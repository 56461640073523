import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { validateWorkEmailInput } from '../formUtils'
import { trackDeal } from '../googleTagManager'
import { sendContactDataToHS } from '../hubspot'
import theme from '../styles/theme'
import Box from './primitives/Box'
import Button from './system/Button'

type Props = {
  hubspotFormId: string
  onSubmit: (email: string) => void
  isSubmitted?: boolean
  requireWorkEmail?: boolean
  buttonContent: string
  // Allows adding a CTA confirmation note to the same container so that the UI doesn't jump after submit.
  contentAfterSubmit: React.ReactNode
  emojiAfterSubmit?: React.ReactNode
}

const validationOptions = {
  disallowEmpty: true,
}

const EmailInputCTA = (props: Props) => {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const emailRef = useRef<HTMLInputElement>(null)

  const handleClick = async event => {
    event.preventDefault()
    if (!emailRef.current || isLoading) {
      return
    }

    const isValid = props.requireWorkEmail
      ? validateWorkEmailInput(emailRef.current, validationOptions)
      : emailRef.current.reportValidity()

    if (!isValid) {
      return
    }

    setIsLoading(true)
    const contactData = {
      email,
    }
    try {
      await sendContactDataToHS(contactData, props.hubspotFormId)
      setEmail('')
      props.onSubmit(email)
      trackDeal('email', contactData)
    } catch (error) {
      // @ts-ignore
      Sentry.captureException(error) // eslint-disable-line
      alert('Something went wrong when submitting email')
    } finally {
      setIsLoading(false)
    }
  }

  if (props.isSubmitted) {
    return (
      <Box
        font="small"
        color="white"
        borderRadius={12}
        paddingY={16}
        paddingLeft={32}
        paddingRight={16}
        css={`
          a {
            color: white;
            font-weight: 500;
            border-bottom: 1px ${theme.colors.linkUnderline} solid;

            &:hover {
              border-bottom-color: ${theme.colors.linkUnderlineHover};
              color: white;
            }
          }
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(4px);
        `}
        display="flex"
        gap={24}
        alignItems="center"
        textAlign="left"
      >
        {props.emojiAfterSubmit ? (
          <Box fontSize={28}>{props.emojiAfterSubmit}</Box>
        ) : null}
        <div>{props.contentAfterSubmit}</div>
      </Box>
    )
  }

  return (
    <InputContainer>
      <Input
        disabled={isLoading}
        required
        ref={emailRef}
        type="email"
        placeholder={props.requireWorkEmail ? 'Your work email' : 'Your email'}
        value={email}
        onChange={e => {
          setEmail(e.target.value)
          if (props.requireWorkEmail) {
            validateWorkEmailInput(e.target, validationOptions)
          }
        }}
      />
      <Button
        variant="primary"
        size="large"
        onClick={handleClick}
        disabled={isLoading}
        type="submit"
      >
        {isLoading ? 'Sending...' : props.buttonContent}
      </Button>
    </InputContainer>
  )
}

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: hidden;
  background: #ffffff;
  color: ${theme.colors.black500};
  box-shadow: 0px 0px 0px 1px rgba(136, 145, 240, 0.2),
    0px 13px 12px rgba(0, 0, 0, 0.05), 0px 17px 38px rgba(23, 23, 26, 0.05);
  border-radius: 12px;

  button {
    border-radius: 0 0 12px 12px;
    height: 44px;
  }

  @media screen and (max-width: 737px) {
    input,
    button {
      text-align: center;
      height: 56px;
    }
  }

  @media screen and (min-width: 737px) {
    flex-direction: row;
    padding: 12px 12px 12px 24px;

    button {
      border-radius: 12px;
    }
  }
`

const Input = styled.input`
  border: none;
  width: 100%;
  font-size: 20px;

  &:focus {
    outline: none;
  }
`

export default EmailInputCTA
